@import './base/variables';
@import './base/button';
@import './base/menu';
@import './base/select';
@import './base/datepicker';
@import './base/table';
@import './base/forms';
html{
    scroll-behavior: smooth;
}
input,
button,
textarea,
select,
.MuiMenuItem-root{
    font-family: $font-dmsans, sans-serif !important;
}
*{
    box-sizing: border-box;
}
body{
    font-family: $font-dmsans, sans-serif;
    background: #F6F6F6;
}
@import './layouts/header';

@import './components/addticket.scss';

@import './pages/dashboard';
@import './pages/ticketlist';
@import './pages/ticketdetails';
@import './pages/profile';
@import './pages/userform';
@import './pages/login';

.app-page-body{
    padding: 0 20px 20px 20px;
}
.app-page-wrapper{
    padding:30px 20px;
    background-color: #ffffff;
    border-radius: 20px;
}
.assign-agent{
    background-color: rgba(255, 122, 0, 0.8)!important;
    color: white !important;
    position: sticky !important;
    bottom: 0 !important;
}

/* Styles for the container of the table and spinner */
.app-table-container {
    position: relative;
  }
  
  /* Your existing styles for the table component, adjust as needed */
  .app-table-component {
    /* Add your existing styles here */
  }
  
  /* Spinner container */
  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
//   /* Adjust specificity by adding a unique class for CustomDatePicker */
// .customDatePicker .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
//   color: rgba(0, 0, 0, 0.6) !important;
// }
// .customDatePicker .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
//   border-color: rgba(0, 0, 0, 0.23) !important;
// }
// .customDatePicker .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline:hover{
//   border-color: rgba(0, 0, 0, 0.6) !important;
// }
  .MuiButtonBase-root.menu-btn.--useractive {
    background-color: rgba(255,122,0,.2);
    color: #262626;
    font-weight: 700;
}

  .spinner-inner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #ccc;
    border-top: 4px solid rgb(255 122 0); 
    animation: spin 1s linear infinite; 
  }
  .package-details-container {
    border-radius: 10px 10px 0 0;
    box-shadow: 0 0 4px #eaeaea;
    background-color: #ffffff;
    padding: 20px; 
    margin-bottom: 10px;

  }
  .pack-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .package-details-container h3 {
    color: #333333; 
  }
  
  .package-details-container p {
    margin: 10px 0; 
    color: #666666; 
  }
  
  /* Spinner animation keyframes */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  