.app-page-login{
    height: 100vh;
    background-image: url('../../../public/images/background.svg');
    background-position: center;
    background-size: cover;
    &__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    &__card{
        background-color: #ffffff;
        width: 100%;
        max-width: 500px;
        padding: 30px;
        border-radius: 20px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        h2{
            margin:0 0 10px 0;
            font-size: 28px;
            color: #262626;
        }
        p{
            margin:0;
            color: #838383;
        }
    }
    &__formgroup{
        display: flex;
        flex-direction: column;
    }
    &__field{
        margin: 10px 0;
    }
    &__forgotpassword{
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
    &__button{
        display: flex;
        justify-content: center;
        margin-top:20px;
        .MuiButtonBase-root{
            font-weight: 600;
            .MuiSvgIcon-root{
                margin-right: 5px;
                font-size: 20px;
            }
        }
    }
    &__rememberpassswprd{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}