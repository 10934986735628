.app-page-ticketlist{
    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 20px 0;
        &--title{
            margin:0;
        }
        &--actions{
            margin:0;
            list-style: none;
            &item{
                .MuiSvgIcon-root{
                    margin-right:4px;
                }
            }
        }
    }
}
.table-assigne{
    &__list{
        display: flex;
        list-style: none;
        padding:0;
        margin:0;
        &item{
            &:nth-child(2){
                position: relative;
                left: -8px;
            }
            &:nth-child(3){
                position: relative;
                left: -16px;
            }
            &:nth-child(4){
                position: relative;
                left: -12px;
            }
            &:hover{
                z-index: 9;
            }
        }
    }
    &-box{
        position: relative;
        width:35px;
        height: 35px;
        &:hover{
            .table-assigne-remove{
                width: 10px;
                height: 10px;
            }
        }
    }
    &-pic{
        width:35px;
        height: 35px;
        object-fit: cover;
        border-radius: 50%;
    }
    &-text{
        width:35px;
        height: 35px;
        font-size: 15px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #838383;
        background-color: #f6f6f6;
        border: 1px solid #eaeaea;
        border-radius: 50%;
        cursor: default;
    }
    &-remove{
        position: absolute;
        top:0;
        right:0;
        width: 0px;
        height: 0px;
        transition: 0.2s all ease-in-out;
        cursor: pointer;
        &:hover{
            width: 12px !important;
            height: 12px !important;
        }
    }
    &-add{
        &__addbtn{
            &.MuiButtonBase-root{
                width:35px;
                height:35px;
                padding:0;
                background-color: #f6f6f6;
                border: 1px solid #eaeaea;
                img{
                    width:22px;
                    height:22px;
                }
            }
        }
    }
}

.plus-sign{
   
    width: 1em;
    margin-top: 0px;
    margin-left: -8px;
    display: block;
    position: absolute;
    color: white;
    border: 0.15em solid white;
    border-radius: 1em;
    box-shadow: 0 0 0.2em #444;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: 'Courier New', Courier, monospace;
    line-height: 1em;
    background-color: #0275d8;
}
.plus{
   color: rgb(255, 122, 0);
}
._1hzz{
    text-decoration: none;
}