.MuiButtonBase-root{
    &.MuiButton-root{
        text-transform: initial;
        font-family: $font-dmsans;
    }
    &.menu-btn{
        font-weight: 500;
        font-size: 15px;
        padding: 5px 14px;
        &.MuiButton-text{
            color: #828282;
        } 
        img{
            width: 0;
            visibility: hidden;
            transition: 0.2s all ease-in-out;
        }
    }
    &.MuiButton-text{
        &Primary{
            color: $primary-color;
            &:hover{
                color: $primary-color-shade-1;
            }
        }
    }
    &.MuiButton-contained{
        &Primary{
            background-color: $primary-color;
            &:hover{
                background-color: $primary-color-shade-1;
            }
        }
    }
    &.notification-btn{
        svg{
            width:24px;
            height: 24px;
        }
    
    }
    &.profile-menu-btn{
        align-items: center;
        .profile-menu-btn{
            &-pic{
                width:30px;
                height: 30px;
                object-fit: cover;
                border-radius: 50%;
                margin-right: 13px;
            }
            &-title{
                color: #262626;
                font-size: 14px;
                font-weight: 500;
                max-width: 90px;
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            &-arrow{
                width: 14px;
                margin-left: 10px;
            }
        }
    }
}