.MuiFormControl-root{
    font-family: $font-dmsans !important;
    cursor: pointer;
    &.ticket-duedate{
        .MuiInputBase-input{
            padding:0 !important;
            font-size: 14px;
            font-weight: 400;
            cursor: pointer;
        }
        .MuiOutlinedInput-notchedOutline{
            border-color: transparent !important;
        }
    }
}