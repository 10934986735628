.app-page-profile{
    padding: 80px 30px 40px 30px;
    &__wrapper{
        display: flex;
        justify-content: center;
        align-items: center;;
    }
    &__card{
        border-radius: 20px;
        background-color: #ffffff;
        box-shadow: 0 0 4px #eaeaea;
        padding: 20px;
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: column;
    }
    &__image{
        align-self: center;
        width: 120px;
        height: 120px;
        position: relative;
        img{
            width: 120px;
            height: 120px;
            border-radius: 50%;
            object-fit: cover;
        }
        .MuiButtonBase-root{
            width: 30px;
            height: 30px;
            background-color: $primary-color;
            position: absolute;
            bottom:0;
            right:0;
            svg{
                padding: 3px;
                color: #ffffff;
            }
            &:hover{
                 background-color: $primary-color-shade-1;
                 svg{
                    color: #ffffff;
                 }
            }
        }
    }
    &__info{
        margin: 20px 0 0 0;
        &list{
            padding:0;
            margin:0;
            list-style: none;
            display: flex;
            flex-direction: column;
            &item{
                margin:10px 0;
                &.--button{
                    margin-top: 30px;
                    align-self: center;
                }
                h6{
                    color: rgba(0, 0, 0, 0.6);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 1.4375em;
                    margin:0;
                }
                p{
                    margin:5px 0 0 0;
                }
                .MuiFormControl-root{
                    width: 100%;
                }
            }
        }
    }
}