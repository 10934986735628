$cl : ".app-page-dashboard";
#{$cl}{
    &__card{
        &item{
            border-radius: 8px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            padding: 30px 20px;
            display: flex;
            height: 100%;
            &--image{
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                svg{
                    width: 30px;
                    height: 30px;
                }
            }
            &--content{
                padding-left: 20px;
                width: calc(100% - 40px);
                display: flex;
                flex-direction: column;
                h1{
                    margin:0;
                    padding:0;
                    font-weight: 900;
                    font-size: 24px;
                    color: #262626;
                    margin-bottom: 10px;
                }
                p{
                    margin:0;
                    padding:0;
                    font-weight: 500;
                    font-size: 15px;
                    color: #838383;
                }
            }
            &.--orange{
                .app-page-dashboard__carditem{
                    &--image{
                        background-color: rgb(255 139 79 / 10%);
                        svg{
                            fill: rgb(255 139 79);
                            path{
                                fill: rgb(255 139 79);
                            }
                        }
                    }
                }
            }
            &.--yellow{
                .app-page-dashboard__carditem{
                    &--image{
                        background-color: rgb(247 184 75 / 10%);
                        svg{
                            fill: rgb(247 184 75);
                            path{
                                fill: rgb(247 184 75);
                            }
                        }
                    }
                }
            }
            &.--red{
                .app-page-dashboard__carditem{
                    &--image{
                        background-color: rgb(241 85 108 / 10%);
                        svg{
                            fill: rgb(241 85 108);
                            path{
                                fill: rgb(241 85 108);
                            }
                        }
                    }
                }
            }
            &.--green{
                .app-page-dashboard__carditem{
                    &--image{
                        background-color: rgb(26 188 156 / 10%);
                        svg{
                            fill: rgb(26 188 156);
                            path{
                                fill: rgb(26 188 156);
                            }
                        }
                    }
                }
            }
            &.--blue{
                .app-page-dashboard__carditem{
                    &--image{
                        background-color: rgb(102 88 221 / 10%);
                        svg{
                            fill: rgb(102 88 221);
                            path{
                                fill: rgb(102 88 221);
                            }
                        }
                    }
                }
            }
        }  
    }
    &__charts{
        margin-top: 40px;
    }
    &__piechart{
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        &--title{
            margin:0 0 20px 0;
        }
        &--content{
            .apexcharts-legend-marker{
                border-radius: 4px !important;
                margin-right: 8px !important;
            }
            .apexcharts-legend-text{
                font-family: $font-dmsans !important;
                font-size: 15px !important;
                font-weight: 500 !important;
            }
            .apexcharts-text.apexcharts-datalabel-label{
                font-family: $font-dmsans !important;
                font-size: 16px !important;
                font-weight: 500 !important;
            }
            .apexcharts-text.apexcharts-datalabel-value{
                font-family: $font-dmsans !important;
                font-size: 22px !important;
                font-weight: 700 !important;
            }
        }
    }
}
.app-page-dashboard-checkin-employees {
   display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 300px;
}