$cl : ".app-layout-header";
#{$cl}{
    padding: 12px 40px;
    border-bottom: 1px solid #eaeaea;
    box-shadow: 0 0 4px #eaeaea;
    &__wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__left{
        display: flex;
        align-items: center;
    }
    &__menu{
        display: flex;
        align-items: center;
        list-style: none;
        padding:0;
        margin:0 0 0 60px;
        &--item{
            margin:0 10px;
        }
    }
    &__profile{
        display: flex;
        align-items: center;
        padding:0;
        margin:0;
        list-style:none;
        &--item{
            .MuiButtonBase-root.notification-btn{
                margin-right: 20px;
            }
        }
    }
}
.app-logo{
    width: 180px;
    height: 60px;
    object-fit: contain;
    padding: 2px 0;
}
.notification-btn-dot{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #0275d8;
    position: absolute;
    top: 10px;
    right: 10px;
}
.notification-list{
    list-style: none;
    padding:10px;
    margin: 0;
    display: flex;
    flex-direction: column;
    max-width: 300px;
    max-height: 300px;
    &-item{
        display: flex;
        align-items: flex-start;
        padding:10px 5px;
        &:not(:last-child){
            border-bottom: 1px solid #eaeaea;
        }
        .MuiSvgIcon-root{
            background-color: $primary-color-shade-4;
            width: 25px;
            height: 25px;
            padding: 4px;
            border-radius: 50%;
            color: $primary-color;
            margin-right: 10px;
        }
        span{
            font-size: 14px;
            color: #707070;
            font-weight: 500;
        }
    }
}
.agents-list{
    .MuiButtonBase-root{
        &.menu-btn{
            &.--agentactive{
                margin-right: 20px !important;
                font-weight: 700;
                color: #262626;
                background-color: $primary-color-shade-4;
                &:hover{
                    background-color: $primary-color-shade-4;
                }
                img{
                    width: 15px;
                    margin-right: 10px;
                    position: unset;
                    visibility: visible;
                }
            }
        }
    }   
}
.dashboardpage{
    .MuiButtonBase-root{
        &.menu-btn{
            &.--dashboardactive{
                font-weight: 700;
                color: #262626;
                background-color: $primary-color-shade-4;
                &:hover{
                    background-color: $primary-color-shade-4;
                }
                img{
                    width: 15px;
                    margin-right: 10px;
                    position: unset;
                    visibility: visible;
                }
            }
        }
    }   
}
.userlist{
    .MuiButtonBase-root{
        &.menu-btn{
            &.--useractive{
                font-weight: 700;
                color: #262626;
                background-color: $primary-color-shade-4;
                &:hover{
                    background-color: $primary-color-shade-4;
                }
                img{
                    width: 15px;
                    margin-right: 10px;
                    position: unset;
                    visibility: visible;
                }
            }
        }
    }   
}
.ticketlist{
    .MuiButtonBase-root{
        &.menu-btn{
            &.--ticketactive{
                font-weight: 700;
                color: #262626;
                background-color: $primary-color-shade-4;
                &:hover{
                    background-color: $primary-color-shade-4;
                }
                img{
                    width: 15px;
                    margin-right: 10px;
                    position: unset;
                    visibility: visible;
                }
            }
        }
    }   
}
.ticketlist{
    .MuiButtonBase-root{
        &.menu-btn{
            &.--useractive{
                font-weight: 700;
                color: #262626;
                background-color: $primary-color-shade-4;
                &:hover{
                    background-color: $primary-color-shade-4;
                }
                img{
                    width: 15px;
                    margin-right: 10px;
                    position: unset;
                    visibility: visible;
                }
            }
        }
    }   
}