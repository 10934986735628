$cl : ".app-page-userform";

#{$cl}{
    padding-bottom: 30px;
    &__strip{
        background-color: $primary-color;
        padding: 40px 30px 80px 30px;
        &text{
            h1{
                color: #ffffff;
                font-size: 36px;
            }
        }
    }
    &__wrapper{
        padding: 0 30px;
        margin-top: -70px;
        position: relative;
        display: flex;
        justify-content: center;
    }
    &__card{
        background-color: #ffffff;
        width: 100%;
        max-width: 800px;
        padding: 30px;
        border-radius: 20px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
}
.userform__field{
    &--button{
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
    }
}
.upload-files-box{
    display: flex;
    flex-direction: column;
}
.upload-files-list{
    display: flex;
    flex-wrap: wrap;
    padding:0;
    margin:0;
    list-style:none;
    &item{
        border: 1px dashed #eaeaea;
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px 10px 0 0;
        position: relative;
        .uploadfile-title{
            font-size: 11px;
            color: #838383;
            margin-top: 5px;
        }
        .MuiSvgIcon-root.uploadfile-icon{
            color: #aeaeae;
        }
        .MuiButtonBase-root{
            position: absolute;
            top:-10px;
            right:-10px;
            svg{
                font-size: 15px;
                color: #ff0000;
            }
        }
    }
    &button{
        .MuiButtonBase-root{
            border: 1px dashed $primary-color;
            margin: 10px 0 0 0;
            background-color: transparent;
            display: flex;
            flex-direction: column;
            width: 80px;
            height: 80px;
            &:not(:hover){
                box-shadow: none;
            }
            &:hover{
                background-color: transparent;
            }
            .uploadfile-title{
                font-size: 11px;
                color: $primary-color;
                margin-top: 5px;
                text-align: center;
            }
            .MuiSvgIcon-root.uploadfile-icon{
                color: $primary-color;
            }
        }
    }
}