.MuiMenu-root{
    font-family: $font-dmsans;
    &.add-assignee-menu{
        .MuiMenuItem-root{
            .MuiCheckbox-root{
                padding: 4px;
                margin-right: 5px;
                color: $primary-color-shade-1;
            }
            .MuiTypography-root{
                font-family: $font-dmsans;
                font-size: 15px;
            }
        }
    }
}