.MuiTable-root{
    .MuiTableCell-root{
        font-family: $font-dmsans;
    }
}
.table-heading{
    margin:0;
    color: #262626;
    font-size: 26px;
    font-weight: 600;
}
.app-table-component{
    .MuiPaper-root{
        border-radius: 10px 10px 0 0;
        box-shadow: 0 0 4px #eaeaea;
    }
    .MuiToolbar-root{
        border-radius: 10px 10px 0 0;
    }
}