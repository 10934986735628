.MuiFormLabel-root,
.MuiInputBase-input,
.MuiTypography-root{
    font-family: $font-dmsans !important;
}
.MuiFormControl-root{
    .MuiFormLabel-root{
        &.Mui-focused{
            color: $primary-color;
        }
    }
    .MuiInputBase-root{
        &:hover{
            &:before{
                border-bottom:2px solid $primary-color-shade-2 !important;
            }
        }
        &.Mui-focused{
            .MuiOutlinedInput-notchedOutline,
            &:after{
                border-color: $primary-color-shade-2;
            }
        }
    }
}
.userform__field--label{
    font-size: 15px;
    color:#262626;
}