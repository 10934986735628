.MuiInputBase-root{
    font-family: $font-dmsans !important;
    &.proiority-select{
        background-color: #f6f6f6;
        
        &.--low{
            background-color: rgb(102, 88, 221);
            .MuiSelect-select,
            .MuiSelect-icon{
                color: #ffffff;
            }
        }
        &.--medium,
        &.--inprogress{
            background-color: rgb(247, 184, 75);
            .MuiSelect-select,
            .MuiSelect-icon{
                color: #ffffff;
            }
        }
        &.--high,
        &.--onhold{
            background-color: rgb(241, 85, 108);
            .MuiSelect-select,
            .MuiSelect-icon{
                color: #ffffff;
            }
        }
        &.--closed{
            background-color: rgb(26, 188, 156);
            .MuiSelect-select,
            .MuiSelect-icon{
                color: #ffffff;
            }
        }
        .MuiSelect-select{
            padding: 5px 25px 5px 10px !important;
            font-size: 14px;
            font-weight: 500;
        }
        .MuiSelect-icon {
            right: 0;
        }
        .MuiOutlinedInput-notchedOutline{
            border-color: transparent !important;
        }
    }
}